import { React, useRef, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { computeHeadingLevel } from "@testing-library/react";

function GenerateCodePage() {
  const basePath = process.env.PUBLIC_URL;
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();
  const location = useLocation();

  const [apiError, setApiError] = useState({
    errorMessage: "",
    error: "",
  });
  const csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  const generateVerificationCode = () => {
    setApiError({
      ...apiError,
      error: true,
    });
    /*let axiosConfig = {
      headers: {
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
      },
    }*/
    axios.defaults.withCredentials = true;
    axios
      .post(baseEndPoint + "generateNonce", {
        withCredentials: true,
        headers: {
          "X-CSRF-TOKEN": csrfToken,
        },
      })
      .then((res) => {
        setApiError({
          ...apiError,
          error: false,
        });

        navigate(basePath + "/verifyCode");
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setApiError({
            ...apiError,
            errorMessage: "Verification code generation failed.",
          });
        }
        if (err.code == "ERR_NETWORK") {
          setApiError({
            ...apiError,
            errorMessage: "Network Error",
          });
        }
      });
  };

  const setSpinner = () => {
    if (apiError.error) {
      return <div className="padding-span margin-left20 loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span
            className={apiError.errorMessage ? "margin-left20 red-icon" : ""}
          >
            {apiError.errorMessage}
          </span>
        </span>
      );
    }
  };
  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-3 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">Step 3 - Verification Code</div>
            </div>
          </div>
          <br />
          <div className="tile">
            <p>
              In order to verify your identity a verification code will be sent
              to your <b>line manager</b>.
            </p>
            <p>
              This verification code is valid for 24 hours and is required to
              generate the <b>Temporary Access Pass</b>.
            </p>
            <p>
              <b>Caution: </b>Before clicking on generate verification code
              button please ensure that you can receive this code from your line
              manager
            </p>
          </div>

          <div className="row tile margin-top20">
            <button
              className="btn primary"
              onClick={(e) => generateVerificationCode()}
            >
              Generate Verification Code
            </button>
            {setSpinner()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateCodePage;
