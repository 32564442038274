import { React, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

function VerificationCodePage() {
  const basePath = process.env.PUBLIC_URL;
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const location = useLocation();
  const verificationCodeRef = useRef();
  const [isError, setIsError] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);
  const [isRevokeError, setIsRevokeError] = useState(false);
  const [revokeError, setRevokeError] = useState({
    errorMessage: "",
    error: "",
  });
  const [apiError, setApiError] = useState({
    errorMessage: "",
    error: "",
  });
  const [verificationCode, setVerificationCode] = useState({
    code: "",
    errorMessage: "",
  });

  const navigate = useNavigate();

  const csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );

  const revokeUserToken = () => {
    setRevokeError({
      ...revokeError,
      error: true,
    });

    axios
      .post(
        baseEndPoint + "nonce/revokeToken/TAP",
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setRevokeError({
          ...revokeError,
          error: false,
        });

        setIsRevokeError(false);

        // If response is true, then navigate
        if (res?.data) {
          setRevokeError({
            ...revokeError,
            errorMessage: "Token Revoked",
          });
          //
          setTimeout(() => {
            setRevokeError({
              ...revokeError,
              errorMessage: "",
            });
            // Send user to "/strongAuthOptions" page
            navigate(basePath + "/");
          }, 3000);
        } else {
          // Throw error on same page - add any msg here when response false occur
          setRevokeError({
            ...revokeError,
            errorMessage: "Verification code failed",
          });
        }
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setRevokeError({
            ...revokeError,
            errorMessage: "Something went wrong.",
          });
        }
        if (err.code == "ERR_NETWORK") {
          setRevokeError({
            ...revokeError,
            errorMessage: "Network Error",
          });
        }
        setVerificationCode({
          ...verificationCode,
          errorMessage: err.message,
        });
        setIsRevokeError(true);
      });
  };

  const setRevokeSpinner = () => {
    if (revokeError.error) {
      return <div className="padding-span margin-left20 loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span
            className={revokeError.errorMessage ? "margin-left20 red-icon" : ""}
          >
            {revokeError.errorMessage}
          </span>
        </span>
      );
    }
  };

  const sendVerificationCode = () => {
    setApiError({
      ...apiError,
      error: true,
    });
    axios
      .post(
        baseEndPoint + "validateNonce",
        { nonce: verificationCode.code },
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setApiError({
          ...apiError,
          error: false,
        });
        setIsError(false);
        if(res?.data){
           navigate(basePath + "/tap", {
          state: {
            verificationCode: verificationCode.code,
          },
        });
        }
        else{
          setIsValidCode(true);
        }
      })
      .catch((err) => {
        console.log('Error on VerificationCode', err);
        console.log('Error Code', err.code);
        if (err.code == "ERR_BAD_REQUEST") {
          setApiError({
            ...apiError,
            errorMessage: "Wrong Code",
          });
        }
        if (err.code == "ERR_NETWORK") {
          setApiError({
            ...apiError,
            errorMessage: "Network Error",
          });
        }
        setVerificationCode({
          ...verificationCode,
          errorMessage: err.message,
        });
        if(!err){
          navigate(basePath + "/tap", { state: verificationCode.code });
        } else{
          setIsError(true);
        }
        
      });
  };
  const setErrorFunc = (val) => {
    if (val) {
      return (
        <span>
          <i className="icon icon-triangle-warning red-icon"></i>
          <span className="red-icon-text">{verificationCode.errorMessage}</span>
        </span>
      );
    } else return false;
  };

  const setSpinner = () => {
    if (apiError.error) {
      return <div className="padding-span margin-left20 loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span
            className={apiError.errorMessage ? "margin-left20 red-icon" : ""}
          >
            {apiError.errorMessage}
          </span>
        </span>
      );
    }
  };

  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-3 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">Step 4</div>
            </div>
          </div>
          <br />
          <div className="tile">
            <p>
              The verification code has been sent to your line manager or
              colleague.
            </p>
            <p>
              Contact your line manager or colleague for the verification code.
            </p>
          </div>

          <div className="tile">
            <label htmlFor="verificationCode">
              Please enter the verification code below
            </label>
            <input
              ref={verificationCodeRef}
              className="validation"
              type="test"
              name="verificationCode"
              id="verificationCode"
              tabIndex="0"
              autofocus
              autoComplete="off"
              value={verificationCode.code}
              onChange={(e) => {
                setVerificationCode({
                  ...verificationCode,
                  code: e.target.value.trim(),
                });
                setIsValidCode(false);
              }}
            />
            {setErrorFunc(isError)}
          </div>
          <br />
          {isValidCode && (
          <div className="invalidCode">
            Verification code failed. Please enter correct verification code.
          </div>
          )}
          <br/>
          <div className="tile">
            <b>Disclaimer: </b>

            <p>
              1. The verification code will be valid for 24 hours from the time
              of generation of the code.
              <br />
              2. Contact the line manager or colleague who has received the
              verification code, to provide it to you.
              <br />
              3. Click on 'Revoke code' if you need to change the colleague to whom you've already sent verification and wish to generate a new verification code.{" "}
              <br />
              <br />
              <span className="red_text">
                * Usage of TAP will be monitored and audited.*
              </span>
            </p>
          </div>

  <div className="btnSubmit">
          <div className=" margin-top20">
            <button
              className={
                verificationCode.code.length > 9
                  ? "btn primary"
                  : "btn primary disabled"
              }
              onClick={(e) => sendVerificationCode()}
            >
              Generate TAP
            </button>
          </div>

          <div className=" margin-top20">
            <button className="btn primary" onClick={(e) => revokeUserToken()}>
              Revoke Code
            </button>
            {/* put anyone out of setRevokeSpinner or setErrorFunc both will show when error occur when api fail */}
          </div>
  </div>
  <div className="errorMessageDiv" >
            {setSpinner()}
  </div>
    <div className="errorMessageDiv" >
            {setErrorFunc(isRevokeError, verificationCode.errorMessage)}
  </div>
    <div className="errorMessageDiv" >
            {setRevokeSpinner()}
  </div>
  
        </div>
      </div>
    </div>
  );
}

export default VerificationCodePage;
