//import { React, useRef, useEffect, useState } from 'react';
import {
  React,
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

const Captcha = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    validateCaptcha() {
      const correctValue = captchaValue.current.value;
      const errorMessage = errMessage.current;
      let validateCaptcha = 0;
      for (let z = 0; z < 6; z++) {
        if (correctValue.charAt(z) != currentCaptcha[z]) {
          validateCaptcha++;
        }
      }
      errorMessage.innerHTML = "Re-Captcha must be filled";

      if (correctValue === "") {
        errorMessage.innerHTML = "Re-Captcha must be filled";
        return 0;
      } else if (validateCaptcha > 0 || captchaValue.length > 6) {
        errorMessage.innerHTML = "Wrong captcha";
        return 0;
      } else {
        errorMessage.innerHTML = "Correct";
        return 1;
      }
    },
  }));
  const validateCaptcha = () => {
    const correctValue = captchaValue.current.value;
    const errorMessage = errMessage.current;

    let validateCaptcha = 0;
    for (let z = 0; z < 6; z++) {
      if (correctValue.charAt(z) != currentCaptcha[z]) {
        validateCaptcha++;
      }
    }
    errorMessage.innerHTML = "Re-Captcha must be filled";

    if (correctValue === "") {
      errorMessage.innerHTML = "Re-Captcha must be filled";
      props.setcaptcha("false");
      return 0;
    } else if (validateCaptcha > 0 || captchaValue.length > 6) {
      errorMessage.innerHTML = "Wrong captcha";
      props.setcaptcha("false");
      return 0;
    } else {
      errorMessage.innerHTML = "Correct";
      props.setcaptcha("true");
      return 1;
    }
  };
  const canvasRef = useRef(null);
  const captchaValue = useRef(null);
  const errMessage = useRef(null);
  const [isChanging, setIsChanging] = useState(false);
  const [currentCaptcha, setCurrentCaptcha] = useState();

  //let currentCaptcha = []
  const getArr = () => {
    let captcha = [];
    for (let q = 0; q < 6; q++) {
      if (q % 2 === 0) {
        captcha[q] = String.fromCharCode(Math.floor(Math.random() * 26 + 65));
      } else {
        captcha[q] = Math.floor(Math.random() * 10 + 0);
      }
    }

    //currentCaptcha = captcha

    setCurrentCaptcha(captcha);
    return captcha;
  };
  const draw = (ctx) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.font = "italic 40px Georgia";
    ctx.textAlign = "center";
    //ctx.rotate(20 * Math.PI / 180);
    ctx.strokeText(getArr().join(""), 150, 90, 100, 100);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    //Our draw come here
    draw(context);
  }, [isChanging]);

  const changeCaptcha = () => {
    setIsChanging((isChanging) => !isChanging);
  };

  return (
    <div className="">
      <div className="captcha_box">
        <canvas ref={canvasRef} />
      </div>

      <div className="captcha_icon">
        <a onClick={changeCaptcha}>
          <i className="icon icon-reload"></i>
        </a>
      </div>

      <div className="tile">
        <input
          ref={captchaValue}
          type="text"
          onKeyUp={validateCaptcha}
          name="reCaptcha"
          id="reCaptcha"
          placeholder="Please Enter The Captcha"
        />
      </div>
      <div ref={errMessage} id="errCaptcha" className="tile"></div>
    </div>
  );
});

export default Captcha;
