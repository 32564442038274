import { React, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

function SuccessMessage() {
  const basePath = process.env.PUBLIC_URL;
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const location = useLocation();
  const [TAP, setTAP] = useState("Loading...");
  const verificationCodeRef = useRef();
  const navigate = useNavigate();
  const csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  const getTap = () => {
    /*let axiosConfig = {
     /* headers: {
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
      },
      postLoginData: {
        //verificationCode: verificationCodeRef.current.value,
      },
    }*/
    // axios.defaults.withCredentials = true
    axios
      .post(
        baseEndPoint + "createMfa",
        { nonce: location.state.verificationCode, nonceType: "MFA" },
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setTAP(res.data);
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setTAP("Bad Request");
        }
        if (err.code == "ERR_NETWORK") {
          setTAP("Network Error");
        }
      });
  };
  useEffect(() => {
    getTap();
  }, []);
  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-6 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">Verification successful</div>
            </div>
          </div>
          <br />
          <div className="tile">
            <p style={{ color: "green" }}>
              Now, you are enabled for re-registration of Microsoft
              Authenticator.
            </p>
            <br />
            {TAP === true ? (
              <p style={{ color: "green" }}>
                For re-registration, Please{" "}
                <a href="https://aka.ms/mfasetup">Click Here.</a>{" "}
              </p>
            ) : (
              <p style={{ color: "red" }}> {TAP} </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessMessage;
