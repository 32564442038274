import { React, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";

function StrongAuthOptionsPage() {
  const basePath = process.env.PUBLIC_URL;
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();
  const location = useLocation();
  const [isTAP, setIsTap] = useState(true);
  const [apiError, setApiError] = useState({
    errorMessage: "",
    error: "",
  });
  const [mfaapiError, setmfaApiError] = useState({
    errorMessage: "",
    error: "",
  });
  const csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  const checkIfNonceIsMapped = (inputType) => {
    let tapormfa = inputType ? "TAP" : "MFA";
    setApiError({
      ...apiError,
      error: true,
    });

    axios.defaults.withCredentials = true;
    axios
      .get(
        baseEndPoint + "nonce/check/" + tapormfa,
        {},
        {
          withCredentials: true,
          headers: {
            "X-CSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setApiError({
          ...apiError,
          error: false,
        });
        if (tapormfa === "MFA") {
          generateRegisterCode(res.data);
        } else {
          generatePasscode(res.data);
        }
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setApiError({
            ...apiError,
            errorMessage: "Verification code generation failed.",
          });
        }
        if (err.code == "ERR_NETWORK") {
          setApiError({
            ...apiError,
            errorMessage: "Network Error",
          });
        }
      });
  };

  const generatePasscode = (serverResponse) => { 
    if (serverResponse) {
      navigate(basePath + "/verifyCode", {});
    } else if (location?.state?.groupCheckPassed) {
      navigate(basePath + "/generateCodeOptions", {
        state: {
          LMEmail: location?.state?.LMEmail,
        },
      });
    } else {
      navigate(basePath + "/generateCode", {
        state: {
          LMEmail: location?.state?.LMEmail,
          nonceValid: location?.state?.nonceValid,
          groupCheckPassed: location?.state?.groupCheckPassed,
        },
      });
    }
  };
  const generateRegisterCode = (serverResponse) => {
    if (serverResponse) {
      navigate(basePath + "/registerverifycode", {});
    } else {
      navigate(basePath + "/registermicrosoftauth", {
        // adding save data here

        state: {
          LMEmail: location?.state?.LMEmail,
          nonceValid: location?.state?.nonceValid,
          groupCheckPassed: location?.state?.groupCheckPassed,
        },
      });
    }
  };

  const setSpinner = () => {
    if (apiError.error) {
      return <div className="padding-span margin-left20 loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span
            className={apiError.errorMessage ? "margin-left20 red-icon" : ""}
          >
            {apiError.errorMessage}
          </span>
        </span>
      );
    }
  };

  const onChangeRadioValue = (e) => {
    // By Default TAP is selected and isTAP is true
    if (e.currentTarget.id == "rbtn2") {
      setIsTap(false);
    } else {
      setIsTap(true);
    }
  };
  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-3 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">Step 2 </div>
            </div>
          </div>
          <br />
          <div className="tile">
            <b>
              Please click Next to generate a Temporary Access Password (TAP)
              if,
            </b>
            <br />
            <p>
              1. You are a new user and want to enroll in a company-provided laptop or a mobile for the first time.
              <br />
              <br />
              2. You are an existing user needing the TAP to register a FIDO2
              key or Microsoft Authenticator again, e.g. due to lost phone or
              due to factory reset, and do not have a working FIDO2 key or a
              MWP-Windows computer.
              <br />
              <br />
              <span style={{ color: "#1174e6", fontWeight: 600 }}>
                Note:
              </span>{" "}
              You do not need a TAP if you have a FIDO2 key or are signed in to
              a Windows computer using Windows Hello for Business (PIN, Face, or
              Fingerprint). Then just{" "}
              <a
                href="https://aka.ms/mfasetup/"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                click here.
              </a>{" "}
              <br />
            </p>
          </div>
          <div className="tile">
            <input
              id="rbtn1"
              name="group1"
              type="radio"
              value="tap"
              checked={isTAP}
              onChange={onChangeRadioValue}
            />
            <label htmlFor="rbtn1">Generate Temporary Access Passcode</label>
          </div>
          <div className="row tile margin-top20">
            <button
              className="btn primary"
              onClick={(e) => checkIfNonceIsMapped(isTAP)}
              disabled={!isTAP}
            >
              Next
            </button>
            {setSpinner()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrongAuthOptionsPage;
