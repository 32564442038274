import { React, useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

import Captcha from "../components/custom/Captcha";
import * as eds from "../eds.min.js";
import validator from "validator";

function SigninPage() {
  const basePath = process.env.PUBLIC_URL;
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const captchaRef = useRef();
  const inpSignum = useRef();
  const location = useLocation();
  const initialState = {
    emailId: "",
    signum: "",
    password: "",
    correctCaptcha: "false",
    errorMessage: "",
  };

  const [loginData, setLoginData] = useState({
    emailId: "",
    signum: "",
    password: "",
    errorMessage: "",
    correctCaptcha: "false",
  });

  useEffect(() => {
    setLoginData({ ...initialState });
  }, []);

  const navigate = useNavigate();
  const [loginError, setLoginError] = useState({
    errorMessage: "",
    error: "",
  });
  const [signumError, setSignumError] = useState({
    errorMessage: "",
    error: "true",
  });

  const enableSignIn = () => {
    if (
      loginData.signum.length > 0 &&
      !signumError.error &&
      loginData.password.length > 0 &&
      loginData.correctCaptcha === "true"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const refreshPage = () => {
    captchaRef.current.changeCaptcha();

    setLoginData({
      ...loginData,
      password: "",
      signum: "",
      correctCaptcha: "false",
    });
    setSignumError({
      ...signumError,
      errorMessage: "Please type your username",
      error: true,
    });
  };

  const sendData = (e) => {
    setLoginError({
      ...loginError,
      error: true,
    });

    const postLoginData = {
      password: loginData.password,

      username: loginData.signum,
    };
    axios
      .post(baseEndPoint + "authenticate", postLoginData, {
        withCredentials: true,
      })
      .then((res) => {
        setLoginError({
          ...loginError,
          error: false,
        });
        // navigate(basePath + "/generateCode", {
          if(res?.data?.canRegister){
            navigate(basePath + "/strongOptionsWithAuth", {
          // adding save data here
          state: {
            LMEmail: res.data.userDetails.managerEmail,
            nonceValid: res?.data?.nonceValid,
            groupCheckPassed: res?.data?.groupCheckPassed,
          },
        })
          }
          else{
             navigate(basePath + "/strongAuthOptions", {
          // adding save data here
          state: {
            LMEmail: res.data.userDetails.managerEmail,
            nonceValid: res?.data?.nonceValid,
            groupCheckPassed: res?.data?.groupCheckPassed,
          },
        });
          }
      })
      .catch((err) => {
        if (err?.response && err?.response?.data) {
          setLoginError({
            ...loginError,
            errorMessage: err.response?.data?.message,
          });
        } else if (err.code == "ERR_NETWORK") {
          setLoginError({
            ...loginError,
            errorMessage: "Network Error",
          });
        } else {
          setLoginError({
            ...loginError,
            errorMessage:
              "Something went wrong. Please contact admin for support.",
          });
        }
      });
    refreshPage();
  };

  const signumValidation = () => {
  //  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i; // regex for email
    const regex = /^[a-zA-Z0-9._%+-]+@(exchacc\.)?ericsson\.com$/;
    if (inpSignum.current.value && regex.test(inpSignum.current.value)) {
      setSignumError({
        ...signumError,
        errorMessage: "Valid entry",
        error: false,
      });
    } else {
      setSignumError({
        ...signumError,
        errorMessage: "Please enter proper email",
        error: true,
      });
    }
  };

  const setSpinner = () => {
    if (loginError.error) {
      return <div className="padding-span loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span className={loginError.errorMessage ? " red-icon" : ""}>
            {loginError.errorMessage}
          </span>
        </span>
      );
    }
  };

  const captchainfo = (data) => {
    setLoginData({ ...loginData, correctCaptcha: data });
    enableSignIn();
  };

  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-3 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">
                <b>Step 1</b>
              </div>
            </div>
          </div>
          <br />
          <div className="tile">
            <p>
              To enroll or recover your account begin by entering your email
              (username), password and the characters in the picture below.
            </p>
          </div>

          <div className="tile">
            <label
              htmlFor={
                signumError.error
                  ? "input-validation-error"
                  : "input-validation"
              }
            >
              Please enter your email *
            </label>
            <input
              ref={inpSignum}
              className={
                signumError.error ? "validation invalid" : "validation valid"
              }
              type="text"
              name="useSignum"
              id={
                signumError.error
                  ? "input-validation-error"
                  : "input-validation"
              }
              tabIndex="0"
              autoFocus
              autoComplete="off"
              value={loginData.signum}
              placeholder="firstname.lastname@ericsson.com"
              onChange={(e) => {
                setLoginData({
                  ...loginData,
                  signum: e.target.value,
                  enableSignIn: enableSignIn(),
                  errorMessage: signumValidation(),
                });
              }}
            />
            <span className="validation-msg">{signumError.errorMessage}</span>
            {/* <span className="textfield-description-msg">Example: user@contoso.onmicrosoft.com or user@contoso.com</span> */}
            <span className="textfield-description-msg">
              Example: firstname.lastname@ericsson.com
            </span>
          </div>

          <div className="tile">
            <label htmlFor="password">Please enter your Password *</label>
            <input
              type="password"
              className="validation"
              name="usePassword"
              id="UserPassword"
              tabIndex="0"
              autoComplete="new-password"
              value={loginData.password}
              onChange={(e) =>
                setLoginData({
                  ...loginData,
                  password: e.target.value,
                  enableSignIn: enableSignIn(),
                })
              }
            />
          </div>

          <div>
            <Captcha ref={captchaRef} setcaptcha={captchainfo}></Captcha>
          </div>

          <div className="row tile margin-top20">
            <button
              className={
                enableSignIn() ? "btn primary" : "btn primary disabled"
              }
              onClick={(e) => sendData()}
            >
              Submit
            </button>
          </div>
          <div className="row tile">{setSpinner()}</div>
        </div>
      </div>
    </div>
  );
}

export default SigninPage;
