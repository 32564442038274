import { React, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

function RegisterVerifyCode() {
  const basePath = process.env.PUBLIC_URL;
  const baseEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const location = useLocation();
  const verificationCodeRef = useRef();
  const [isError, setIsError] = useState(false);
  const [isRevokeError, setIsRevokeError] = useState(false);

  const [apiError, setApiError] = useState({
    errorMessage: "",
    error: "",
  });

  // set this new revoke error/message while revoke api invoke
  const [revokeError, setRevokeError] = useState({
    errorMessage: "",
    error: "",
  });
  const [verificationCode, setVerificationCode] = useState({
    code: "",
    errorMessage: "",
  });

  const navigate = useNavigate();

  const csrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  const sendVerificationCode = () => {
    setApiError({
      ...apiError,
      error: true,
    });

    axios
      .post(
        baseEndPoint + "nonce/validate/MFA",
        { nonce: verificationCode.code },
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setApiError({
          ...apiError,
          error: false,
        });

        setIsError(false);

        // If response is true, then navigate
        if (res?.data) {
          navigate(basePath + "/registersuccessfully", {
            state: {
              verificationCode: verificationCode.code,
            },
          });
        } else {
          // Throw error on same page
          setApiError({
            ...apiError,
            errorMessage: "Verification code failed",
          });
        }
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setApiError({
            ...apiError,
            errorMessage: "Wrong Code",
          });
        }
        if (err.code == "ERR_NETWORK") {
          setApiError({
            ...apiError,
            errorMessage: "Network Error",
          });
        }
        // setApiError({
        //   ...apiError,
        //   errorMessage: err.message,
        // })

        setVerificationCode({
          ...verificationCode,
          errorMessage: err.message,
        });
        //  navigate(basePath + "/tap", { state: verificationCode.code });
        setIsError(true);
      });
  };

  const revokeUserToken = () => {
    setRevokeError({
      ...revokeError,
      error: true,
    });

    axios
      .post(
        baseEndPoint + "nonce/revokeToken/MFA",
        {},
        {
          withCredentials: true,
          headers: {
            "X-XSRF-TOKEN": csrfToken,
          },
        }
      )
      .then((res) => {
        setRevokeError({
          ...revokeError,
          error: false,
        });

        setIsRevokeError(false);

        // If response is true, then navigate
        if (res?.data) {
          // Display Text- Token Revoked. Redirecting to sigin screen.
          // After 2 seconds
          setRevokeError({
            ...revokeError,
            errorMessage: "Token Revoked",
          });
          // navigate(basePath + "/");
          setTimeout(() => {
            setRevokeError({
              ...revokeError,
              errorMessage: "",
            });
            // Send user to "/strongAuthOptions" page
            navigate(basePath + "/");
          }, 3000);
        } else {
          // Throw error on same page - add any msg here when response false occur
          setRevokeError({
            ...revokeError,
            errorMessage: "Verification code failed",
          });
        }
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_REQUEST") {
          setRevokeError({
            ...revokeError,
            errorMessage: "Something went wrong.",
          });
        }
        if (err.code == "ERR_NETWORK") {
          setRevokeError({
            ...revokeError,
            errorMessage: "Network Error",
          });
        }
        setVerificationCode({
          ...verificationCode,
          errorMessage: err.message,
        });
        setIsRevokeError(true);
      });
  };
  const setErrorFunc = (val, msg) => {
    if (val) {
      return (
        <span style={{ marginLeft: "8px" }}>
          <i className="icon icon-triangle-warning red-icon"></i>
          <span className="red-icon-text">{msg}</span>
        </span>
      );
    } else return false;
  };

  const setSpinner = () => {
    if (apiError.error) {
      return <div className="padding-span margin-left20 loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span
            className={apiError.errorMessage ? "margin-left20 red-icon" : ""}
          >
            {apiError.errorMessage}
          </span>
        </span>
      );
    }
  };
  const setRevokeSpinner = () => {
    if (revokeError.error) {
      return <div className="padding-span margin-left20 loading small"></div>;
    } else {
      return (
        <span className="padding-span">
          <span
            className={revokeError.errorMessage ? "margin-left20 red-icon" : ""}
          >
            {revokeError.errorMessage}
          </span>
        </span>
      );
    }
  };

  // create onClick handler for revoke api invocation

  return (
    <div className="row captcha_container">
      <div className="tile xl-4 lg-6 sm-12"></div>
      <div className="xl-4 lg-6 sm-12">
        <div className="card">
          <div className="header sm-12">
            <div className="left">
              <div className="title">
                Step 4- Please enter your verification Code
              </div>
            </div>
          </div>
          <br />
          <div className="tile">
            <p>
              Verification Code along with the instructions has been sent
              successfully to your colleague/Line manager. Kindly contact the
              recipient of the code
            </p>
          </div>

          <div className="tile">
            <label htmlFor="verificationCode">Verification Code</label>
            <input
              ref={verificationCodeRef}
              className="validation"
              type="test"
              name="verificationCode"
              id="verificationCode"
              tabIndex="0"
              autoFocus
              autoComplete="off"
              value={verificationCode.code}
              onChange={(e) =>
                setVerificationCode({
                  ...verificationCode,
                  code: e.target.value.trim(),
                })
              }
            />
            {/* if error under input field is required then call this (setErrorFunc) otherwise remove it */}
            {setErrorFunc(isError, verificationCode.errorMessage)}
          </div>
          <div className="row tile margin-top20">
            <button
              className={
                verificationCode.code.length > 9
                  ? "btn primary"
                  : "btn primary disabled"
              }
              onClick={(e) => sendVerificationCode()}
            >
              Next
            </button>
            {setSpinner()}
          </div>
          <br />
          <div className="tile">
            <b>Instructions: </b>

            <p>
              1. The verification code will be valid for 24 hours from the time
              of generation of the code.
              <br />
              2. Contact the line manager or colleague who has received the
              verification code, to provide it to you.
              <br /> <br />
              <span style={{ color: "#880000" }}>
                <b>Note:</b>
              </span>{" "}
              If you are not able to contact the receiver of the code within the
              time, you can click on <b>Revoke</b> button below and make a new
              request for the verification code.
              <br />
              {/* <span className="red_text">
                * Usage of TAP will be monitored and audited.*
              </span> */}
            </p>
          </div>

          <div className="row tile margin-top20">
            <button className="btn primary" onClick={(e) => revokeUserToken()}>
              Revoke
            </button>
            {setRevokeSpinner()}
            {/* put anyone out of setRevokeSpinner or setErrorFunc both will show when error occur when api fail */}
            {setErrorFunc(isRevokeError, verificationCode.errorMessage)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterVerifyCode;
